<template>
  <section class="editorials">
    <!-- <Loader :loading="loading" /> -->
    <template v-if="!loading">
      <router-link class="editorials__button editorials__link" to="/library">
        Back to Library
      </router-link>
      <h1 class="editorials__title text-title">Publications</h1>
      <div class="editorials__types">
        <BaseButton
          v-for="(editorialType, index) in editorialTypes"
          :key="`editorial-type-${index}`"
          class="editorials__type button--ms button--ellipse"
          :class="{
            'button--outline-white': activeType !== editorialType.key,
            'button--white': activeType === editorialType.key,
          }"
          :text="`${editorialType.title} (${editorialType.count})`"
          @click="filterType(editorialType.key)"
        />
      </div>
      <div class="editorials__inner">
        <div
          class="editorials__item"
          v-for="(editorial, index) in editorials"
          :key="`editorial-${index}`"
          @click="readMore(index)"
          tabindex="0"
          @keyup.enter="readMore(index)"
        >
          <h2 class="editorials__item-title text-base">{{ editorial.title }}</h2>
          <p class="editorials__item-description text-description">{{ editorial.description }}</p>
        </div>
      </div>
      <InfiniteLoading class="infinite-loading" @infinite="loadMore" />
      <div class="editorials__buttons-box">
        <BaseButton
          class="editorials__button editorials__button_mobile button--outline-white button--lg button--ellipse"
          text="Back to Top"
          @click="backToTop"
        />
      </div>
    </template>
  </section>
</template>

<script>
import { scroller } from "vue-scrollto/src/scrollTo";
import { mapGetters, mapMutations, mapActions } from "vuex";
import Loader from "@/components/Loader.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import InfiniteLoading from "vue-infinite-loading";

export default {
  components: {
    BaseButton,
    Loader,
    InfiniteLoading,
  },
  data() {
    return {
      loading: false,
      busy: false,
      page: 1,
      activeType: 0,
    };
  },
  head: {
    title: function () {
      return {
        inner: "GH - Publications",
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },
  computed: {
    ...mapGetters("publicEditorials", {
      editorials: "getEditorials",
      hasMorePages: "getHasMorePages",
      editorialTypes: "getEditorialTypes",
    }),
  },
  async created() {
    this.loading = true;
    await this.apiGetEditorials({ page: this.page });
    await this.apiGetEditorialTypes();
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Library",
        to: "/library",
      },
      {
        title: "Publications",
        to: "/library/publications",
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
    this.loading = false;
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapActions("publicEditorials", ["apiGetEditorials", "apiGetEditorialTypes"]),
    async loadMore($state) {
      this.busy = true;
      if (this.hasMorePages) {
        this.page += 1;
        await this.apiGetEditorials(this.page);
        $state.loaded();
        this.busy = false;
      } else {
        $state.complete();
      }
    },
    readMore(index) {
      this.$router.push({ path: `/library/publications/${this.editorials[index].slug}` });
    },
    backToTop() {
      const firstScrollTo = scroller();
      firstScrollTo(".header_breadcrumbs");
    },
    async filterType(key) {
      this.activeType = key;
      this.page = 1;
      await this.apiGetEditorials({ page: this.page, type: key ? key : "" });
    },
  },
};
</script>

<style lang="scss" scoped>
.editorials {
  position: relative;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20.7rem 28rem;
  @media (max-width: $xxs) {
    padding: 28rem 2.5rem 0;
  }
  &__title {
    margin-bottom: 2rem;
  }
  &__button {
    width: max-content;
    min-width: 21rem;
    @media (max-width: $xxs) {
      min-width: 19rem;
    }
  }
  &__link {
    position: absolute;
    top: 13rem;
    left: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 34px;
    background-color: transparent;
    color: $white;
    font-family: $fontBodoni;
    height: 5rem;
    padding: 0 28px;
    font-size: 2.1rem;
    font-weight: 600;
    line-height: 1.5;
    text-decoration: none;
    border: 1px solid $white;
    border-radius: 100px;
    @media (max-width: $xxs) {
      height: 4.2rem;
      padding: 0 12px;
      top: 13rem;
      left: 2.5rem;
    }
    @media (max-width: $xxxs) {
      font-size: 1.6rem;
    }
    &:hover {
      background-color: rgba($color: $white, $alpha: 0.1);
    }
  }
  &__types {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  &__type {
    width: max-content;
    min-width: 11.6rem;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  &__inner {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 88rem;
    width: 100%;
  }
  &__item {
    cursor: pointer;
    padding: 3rem 0;
    border-bottom: 1px solid $white;
    width: 100%;
    @media (max-width: $xxs) {
      padding: 2.5rem 0;
    }
    &-title {
      font-size: 2.8rem;
      line-height: 1.3;
      letter-spacing: -0.45px;
      margin-bottom: 1.5rem;
      @media (max-width: $xxs) {
        font-size: 2.5rem;
        margin-bottom: 0;
      }
    }
    &-description {
      line-height: 1.5;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      text-overflow: ellipsis;
      overflow: hidden;
      @media (max-width: $xxs) {
        display: none;
      }
    }
  }
  &__buttons-box {
    display: none;
    flex-direction: column;
    align-items: center;
    margin: 5rem 0rem;
    width: 100%;
    @media only screen and (max-width: $xxs) {
      display: flex;
    }
  }
}
</style>
