import { render, staticRenderFns } from "./Editorials.vue?vue&type=template&id=f8b2d2e4&scoped=true"
import script from "./Editorials.vue?vue&type=script&lang=js"
export * from "./Editorials.vue?vue&type=script&lang=js"
import style0 from "./Editorials.vue?vue&type=style&index=0&id=f8b2d2e4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8b2d2e4",
  null
  
)

export default component.exports